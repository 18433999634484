import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";

import imgB1 from "../../assets/image/jpeg/contact-us.jpg";
import imgB2 from "../../assets/image/jpeg/loanerhub-setup.jpg";
import imgB3 from "../../assets/image/jpeg/support-vehicles.jpg";
import imgB4 from "../../assets/image/jpeg/support-contracts.jpg";
import imgB5 from "../../assets/image/jpeg/support-customers.jpg";

const SupportList = () => (
  <>
    {/* <!-- Blog section --> */}
    <Section className="position-relative">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="6" md="6" className="mb-5">
            <PostCard link={"/contact-2"} img={imgB1} title="Contact Support">
              Need a little help? <br /> Reach out and say 'hi' at any time.
            </PostCard>
          </Col>
          <Col lg="6" md="6" className="mb-5">
            <PostCard
              link={"/support-setup"}
              img={imgB2}
              title="Loaner Hub Setup"
            >
              Getting your account setup? <br /> View Setup Guides and FAQs.
            </PostCard>
          </Col>
          <Col lg="4" md="6" className="mb-5">
            <PostCard
              smImg
              link={"/support-vehicles"}
              img={imgB3}
              title="Vehicles"
            >
              Features, Functionality, and Best Practices. <br /> View Guides
              and FAQs.
            </PostCard>
          </Col>
          <Col lg="4" md="6" className="mb-5">
            <PostCard
              smImg
              link={"/support-contracts"}
              img={imgB4}
              title="Contracts"
            >
              Features, Functionality, and Best Practices. <br /> View Guides
              and FAQs.
            </PostCard>
          </Col>
          <Col lg="4" md="6" className="mb-5">
            <PostCard
              smImg
              link={"/support-customers"}
              img={imgB5}
              title="Customers"
            >
              Features, Functionality, and Best Practices. <br /> View Guides
              and FAQs.
            </PostCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default SupportList;
